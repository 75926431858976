body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "sans-serif", "Droid Sans",
    "Pacifico", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Helvetica Neue", "Segoe UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.demo-bg {
  background: #ffac0c;
  margin-top: 60px;
}
.business-hours, .price-list {
  background: rgb(218, 94, 64);
  padding: 40px 14px;
  margin-top: -15px;
  position: relative;
}
.business-hours:before,.price-list:before {
  content: "";
  width: 23px;
  height: 23px;
  background: rgb(90, 3, 3);
  position: absolute;
  top: 5px;
  left: -12px;
  transform: rotate(-45deg);
  z-index: -1;
}
.business-hours .title,.price-list .title {
  font-size: 20px;
  color: rgb(255, 254, 254);
  text-transform: uppercase;
  padding-left: 5px;
  border-left: 4px solid #ffac0c;
}
.business-hours li, .price-list li {
  color: rgb(247, 247, 247);
  line-height: 30px;
  border-bottom: 1px solid #333;
}
.business-hours li:last-child,.price-list li:last-child {
  border-bottom: none;
}
.business-hours .opening-hours li.today {
  color: #ffac0c;
}

.map-container-section {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-section iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.services {
  background: rgb(218, 94, 64);
  padding: 40px 14px;
  margin-top: -15px;
  position: relative;
}
.services .title {
  font-size: 20px;
  color: rgb(255, 254, 254);
  text-transform: uppercase;
  padding-left: 5px;
  border-left: 4px solid #ffac0c;
}
.thread-gallery {
  padding: 40px 14px;
  margin-top: -15px;
  position: relative;
}
.thread-gallery .title {
  font-size: 20px;
  color: rgb(11, 6, 80);
  text-transform: uppercase;
  padding-left: 5px;
  border-left: 4px solid #ffac0c;
}

.browz-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000000;
  z-index: 0;
}
.browz-notice-animated {
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 2;
}

* {
  font-family: "Poppins", sans-serif;
}
.seprator {
  height: 2px;
  width: 56px;
  background-color: #0aaa7a;
  margin: 7px 0 10px 0;
}
.browz-service-card-title {
  font-size: 18px;
  font-weight: bold !important;
  color: rgba(0, 18, 179, 0.993) !important;
  margin-bottom: 1rem !important;
}
.browz-service-card-text {
  font-size: 16px;
  line-height: 1.8;
  font-weight: 500;
  color: #000000;
}
.embed-responsive {
  height: 900px!important;
}
.gift-cards{
  color: rgb(226, 166, 166);
  font-weight: bold;
}
.price-list-menu{
  width: 80%;
}